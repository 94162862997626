.navbar {
  background: #d41367;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  position: fixed;
  top: 0;
  z-index: 999;
}

.nav-admin {
  position: relative;
}

.fa-caret-down {
  margin-left: 5px; /* Adjust the value as needed */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  width: 100%;
  padding: 0 20px;
}

.navbar-logo {
  height: auto;
  margin-bottom: 0; /* Remove bottom margin */
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 1rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1px;
  height: 100%;
  margin: 0 26px;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 961px) {
  .navbar-logo {
    width: 250px; /* Larger size for larger screens */
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 960px) {
  .navbar-container {
    flex-direction: row; /* Ensure row direction on smaller screens */
    align-items: center; /* Align items center */
    padding: 0 20px;
  }

  .fa-caret-down {
    display: none;
  }

  .navbar-logo {
    width: 200px; /* Adjust the width for smaller screens */
    height: auto;
    position: relative; /* Remove absolute positioning */
    top: 0; /* Remove top positioning */
    left: 0; /* Remove left positioning */
    transform: none; /* Remove transform */
    margin-bottom: 15px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #242222;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: block;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
  }
}
