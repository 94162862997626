.dropdown-menu {
  display: none;
  left: 0;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 200px;
  padding: 0;
}

.dropdown-item {
  padding: 12px 16px;
}

.dropdown-link {
  text-decoration: none;
  color: #242424;
  display: block;
  padding: 5px 10px;
}

.dropdown-link:hover {
  background-color: #ddd;
}

.nav-item:hover .dropdown-menu {
  display: block;
}
