.hero-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../public/images/assembly.jpg") center center/cover
    no-repeat;
  z-index: 1; /* Ensure it's above the video background */
}

.hero-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2); /* Adjust opacity to darken image */
  z-index: -1; /* Ensure the overlay is behind the content */
}

.hero-container > h1,
.hero-container > p {
  position: relative;
  color: #fff;
  z-index: 2;
}

.hero-container > h1 {
  font-size: 55px;
  margin-top: 390px;
}

.hero-container > p {
  margin-top: 8px;
  font-size: 22px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 65px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 35px;
    margin-top: 350px;
  }

  .hero-container > p {
    font-size: 15px;
    align-content: center;
    text-align: center;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -2; /* Ensure the video is behind the overlay */
}
